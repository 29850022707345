import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog"
import { cn } from "@/lib/utils";

export default function Modal({ title = "", children, headExtra, noHeader = false, show = false, className = "", closable = true, onClose = () => { } }) {
    const close = () => closable && onClose();
    return (
        <Dialog open={show} onOpenChange={close}>
            <DialogContent closable={closable} className={cn("!z-[999999] flex flex-col", className)}>
                {!noHeader && <DialogHeader className="h-[40px] flex flex-row justify-between items-center gap-4">
                    <DialogTitle>{title}</DialogTitle>
                    {headExtra}
                </DialogHeader>}
                {children}
            </DialogContent>
        </Dialog>
    )
}

